.talent-page header {
  background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../images/history.jpg);
}

.head {
  text-align: end;
}

.detail {
  text-align: start;
}

.title {
  font-family: 'Times New Roman', Times, serif;
  width: 100;
}

.list2 {

  list-style-type: none
}