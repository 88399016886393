@page {
  size: a4 landscape;
  margin: 0px;
}

/* body {
  
  margin: 0;
  padding: 0;
  height: 210mm;
  width: 297mm;
} */

/* body {
  background-image: url(tp244-bg2-04.jpg);
  background-repeat: no-repeat;
  background-size: 297mm 210mm;
} */

.overlay {
  z-index: 9999;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(82, 82, 82, 0.301);
  width: 100%;
  height: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.main{
  margin: 0 !important;
  padding: 0 !important;
}
.background{
  height: 210mm;
  width: 297mm;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.cntnr{
  height: 178mm;
  width: 267.4mm !important;
  margin-top: 16mm !important;
  margin-right: 14.8mm !important;
  margin-bottom: 16mm !important;
  margin-left: 14.8mm !important;
  padding: 0mm !important;
}
.title{
  height: 100px;
  padding-top: 4mm;
  display: flex;
}

.title img{
  height: 30mm !important;
 
  padding-left: 5mm;
  margin-bottom: 0px;
}
.acm-head{
  font-weight: bold !important;
  font-size: 32px !important;
  margin-bottom: 0px;
  font-family: 'Nanum Myeongjo', serif !important;
}
.title .cname{
  
  font-family: 'Nanum Myeongjo', serif !important;
  padding-left: 12mm!important;
  margin-bottom: 0px!important;
  color: rgb(23, 23, 132);
}
.address{
  text-align: center;
  font-size: 22px;
  margin: 0px;
  color: rgb(23, 23, 132);
}
.heading{
  font-family: 'UnifrakturCook', cursive !important;
  text-align: center!important;
  font-size: 55px;
  padding-bottom: 1mm;
  padding-left: 31mm !important;
  padding-right: 31mm !important;
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(23, 23, 132);
}
.p-num{
  margin-top: 0px;
  text-align: center;
  font-size: small;
}
.content{
  margin: 0% 15%;
}
.content p{
  font-family: 'Nanum Myeongjo', serif !important;
  margin: 15px 0px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: rgb(21, 21, 133);
}
.content .name{
  font-size: 35px;
  color: black;
  font-family: 'Nanum Myeongjo', serif !important;
  font-weight: bolder;
}
.content .cName{
  font-size: 27px;
  font-weight: bolder !important;
  font-family: 'Nanum Myeongjo', serif !important;
  color: rgb(21, 21, 133);
}
.bottom{
  margin: 0px 5% 0px 5% !important;
  display: flex;
  justify-content: space-between;
  text-align: center
}

.bottom img{
  width: 35mm;
}
.stamp {
  width: 30mm;
  height: 30mm;
}

@media print {
  .loading {
    visibility: hidden;
  }

  .overlay {
    visibility: hidden;
  }
}
/* @media print {
  .hide-on-print { display:none; }
  .show-on-print-only { display:initial; }
  .page-break-before { page-break-before: always; }
} */

/* @media print {
  body {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
  }
} */
